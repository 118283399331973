


























































































































import BaseButton from '@/components/base/BaseButton.vue';
import { useCreatingBooking, currentBooking, calendarSelection } from '@/composables/useCreatingBooking';
import MemberPage from '@/layouts/MemberLayout/MemberPage.vue';
import { defineComponent, onMounted, onUnmounted, ref } from '@/plugins/composition';
import { formatTimeRange } from '@/utils/date';
import { routeNames } from '@/router/routeNames';
import { useApi } from '@/composables/useApi';
import { debouncedWatch } from '@vueuse/core';
import { PriceResult } from '@/composables/useBookedResources/types';
import QuoteCard from './components/QuoteCard.vue';
import { createReservation, createRepeatingReservation } from '@/composables/useBookedResources/useBookedReservations';
import { useAuthStore } from '@/composables/useAuthStore';
import { useRouter } from '@/router/useRouter';
import { formatISO } from 'date-fns';
import { rules } from '@/composables/useValidation/validations';
import { Resource } from '@/models/booked/Resource';
import { useBookedResources } from '@/composables/useBookedResources';

type DatePair = {
  start_time: string;
  end_time: string;
};

export default defineComponent({
  components: { MemberPage, BaseButton, QuoteCard },
  name: 'ConfirmBookingPage',
  setup() {
    const price = ref<PriceResult | null>(null);
    const booking = useCreatingBooking();
    const loading = ref(false);
    const valid = ref(false);
    const success = ref(false);
    const error = ref(false);
    const errorMessage = ref('');
    const dates = ref<{ start: Date; end: Date }[]>([]);
    const resource = ref<null | Resource>(null);
    const { getResource } = useBookedResources();
    const terms = ref(false);

    const { user } = useAuthStore();

    const { post } = useApi();

    const { router } = useRouter();

    const goToBookings = () => {
      router.push({ name: routeNames.spaces.book });
    };

    onMounted(async () => {
      const id = booking.currentResource.value?.id;

      if (!id) {
        return;
      }

      resource.value = await getResource(id);
    });

    const getRepeatingQuote = async () => {
      return await post<PriceResult>('/reservations/repeating/quote', {
        start_date: formatISO(booking.repeatingOptions.value.startDate),
        end_date: formatISO(booking.repeatingOptions.value.endDate),
        start_time: formatISO(booking.repeatingOptions.value.startTime),
        end_time: formatISO(booking.repeatingOptions.value.endTime),
        interval_type: booking.repeatingOptions.value.intervalType,
        interval: booking.repeatingOptions.value.interval,
        weekly_days: booking.repeatingOptions.value.days,
        generatingIncome: currentBooking.generatingIncome,
        funded: currentBooking.funded,
        performance: currentBooking.performance,
        attendees: currentBooking.attendees,
      });
    };

    const getNonRepeatingQuote = async () => {
      return await post<PriceResult>('/reservations/quote', {
        start: calendarSelection.startSlot?.startDateTime,
        end: calendarSelection.endSlot?.endDateTime,
        generatingIncome: currentBooking.generatingIncome,
        funded: currentBooking.funded,
        performance: currentBooking.performance,
        attendees: currentBooking.attendees,
      });
    };

    const getDates = async () => {
      const _dates = await post<DatePair[]>('/reservations/repeating/dates', {
        start_date: formatISO(booking.repeatingOptions.value.startDate),
        end_date: formatISO(booking.repeatingOptions.value.endDate),
        start_time: formatISO(booking.repeatingOptions.value.startTime),
        end_time: formatISO(booking.repeatingOptions.value.endTime),
        interval_type: booking.repeatingOptions.value.intervalType,
        interval: booking.repeatingOptions.value.interval,
        weekly_days: booking.repeatingOptions.value.days,
        generatingIncome: currentBooking.generatingIncome,
        funded: currentBooking.funded,
        performance: currentBooking.performance,
        attendees: currentBooking.attendees,
      });

      dates.value = _dates.map((pair: DatePair) => ({
        start: new Date(pair.start_time),
        end: new Date(pair.end_time),
      }));
    };

    onMounted(async () => {
      if (!booking.currentResource.value) {
        router.push({ name: routeNames.spaces.book });
      }
      if (booking.isRepeating.value) {
        await getDates();
      }
    });

    const calculatePrice = async () => {
      const result = booking.isRepeating.value ? await getRepeatingQuote() : await getNonRepeatingQuote();

      price.value = result;
      setTimeout(() => {
        loading.value = false;
      }, 250);
    };

    const confirm = async () => {
      if (booking.isRepeating.value) {
        return await confirmRepeatingBookings();
      } else {
        return await confirmBooking();
      }
    };

    const confirmBooking = async () => {
      const response = await createReservation(
        {
          start: calendarSelection.startSlot?.startDateTime as unknown as Date,
          end: calendarSelection.endSlot?.endDateTime as unknown as Date,
          description: currentBooking.description as string,
          attendees: currentBooking.attendees as number,
          income: currentBooking.generatingIncome,
          funding: currentBooking.funded,
          performance: currentBooking.performance,
        },
        user.value.uuid,
        booking.currentResource.value?.id as number,
        booking.currentResource.value?.name as string
      ).catch(() => {
        error.value = true;
        errorMessage.value =
          'Something went wrong when creating your booking. Some availabilities may have changed. Please edit your booking and try again.';
      });

      if (response) {
        success.value = true;
      }
    };

    const confirmRepeatingBookings = async () => {
      const response = await createRepeatingReservation(
        {
          start: booking.repeatingOptions.value.startDate,
          end: booking.repeatingOptions.value.endDate,
          description: currentBooking.description as string,
          attendees: currentBooking.attendees as number,
          income: currentBooking.generatingIncome,
          funding: currentBooking.funded,
          performance: currentBooking.performance,
          start_time: formatISO(booking.repeatingOptions.value.startTime),
          end_time: formatISO(booking.repeatingOptions.value.endTime),
          interval_type: booking.repeatingOptions.value.intervalType,
          interval: booking.repeatingOptions.value.interval,
          weekly_days: booking.repeatingOptions.value.days,
        },
        user.value.uuid,
        booking.currentResource.value?.id as number,
        booking.currentResource.value?.name as string
      ).catch(() => {
        error.value = true;
        errorMessage.value =
          'Something went wrong when creating your booking. Some availabilities may have changed. Please edit your booking and try again.';
      });

      if (response) {
        success.value = true;
      }
    };

    onUnmounted(() => {
      booking.resetCurrentBooking();
      booking.resetCalendar();
    });

    debouncedWatch(
      currentBooking,
      () => {
        if (valid.value) {
          loading.value = true;
          calculatePrice();
        }
      },
      { debounce: 500 }
    );

    const capacityRule = (v: number) => {
      const cap = resource.value?.capacity as string;
      if (!cap) {
        return true;
      }

      return v <= parseInt(cap) || 'The limit for this resource is ' + cap;
    };

    return {
      formatTimeRange,
      ...booking,
      routeNames,
      price,
      loading,
      valid,
      confirm,
      success,
      goToBookings,
      error,
      errorMessage,
      currentBooking,
      calendarSelection,
      rules,
      dates,
      terms,
      capacityRule,
    };
  },
});
