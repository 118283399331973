




















import { defineComponent, PropType } from '@/plugins/composition';
import { PriceResult } from '@/composables/useBookedResources/types';
import { formatPrice } from '@/utils/number';

export default defineComponent({
  name: 'QuoteCard',
  props: {
    price: {
      type: Object as PropType<PriceResult>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      formatPrice,
    };
  },
});
